import PropTypes from 'prop-types';
import React from 'react';

function Logo(props) {
  return (
    <svg
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      clipRule="evenodd"
      viewBox="0 0 1419 425"
      {...props}
    >
      <g fill="#fff" fillRule="nonzero">
        <path d="M239.78 236.63L254 252.07l.89-98.31h23.21v150.59h-4.16l-85.55-86.14-11.88-13.07v98h-23.76V152h4.75l82.28 84.63zm261.09 66.23L442.35 217l-58.51 85.84h-28.52l72.18-104L361.56 100H391l51.08 80.5 52.55-80.5h28.51l-66.23 98.9 73.06 104-29.1-.04zM699 175.14h-49.3v127.72H626V175.14h-49.3v-21.38H699v21.38zm105.16 105.15h88.51v22.57H780.4V100h23.76v180.29zm191.7-42.18l7.43 18.71 8-18.41 36.23-84.65h26.14l-69.5 150.29h-3L932.3 153.76h28.22l35.34 84.35zm181.22 42.18h88.51v22.57h-112.27V100h23.76v180.29zm220.39-1.29v9.47h7.53v-8.24h2.61v8.24h8.13V279h2.61v12.22h-23.45V279h2.57zm-2.74-17.09c-.02-1.845.4-3.669 1.2-5.33l2.47.52a11.74 11.74 0 00-1.07 4.7c0 2.61 1.17 3.92 3 3.92 4.67 0 4.91-9.65 11.91-9.65 3.37 0 6.25 2.27 6.25 7.38a11.98 11.98 0 01-1.44 5.8l-2.58-.51a10.876 10.876 0 001.45-5.25c0-3.23-1.27-4.67-3.34-4.67-5.21 0-5.49 9.82-12.18 9.82-3.4 0-5.67-2.44-5.67-6.73zm2.74-34.01v8.2h20.84v2.9h-20.84v8.21h-2.61V227.9h2.61zm19.06-7.9c.98 0 1.79.808 1.79 1.79s-.81 1.79-1.79 1.79-1.79-.808-1.79-1.79v-.02c0-.971.8-1.77 1.77-1.77h.02zm-.83-29.92h2.61V205h-.68c-5.57-5.67-11.4-11-15.59-11-3.26 0-4.71 2.16-4.71 5 .03 1.713.37 3.406 1 5l-2.57.62c-.74-1.8-1.14-3.723-1.17-5.67 0-4.39 2.34-7.89 7.07-7.89 5 0 11.2 5.84 14 8.75l.04-9.73zm-20.94-18.57c0-5.67 4.77-9.45 12-9.45s11.78 3.78 11.78 9.45-4.71 9.44-11.78 9.44c-7.07 0-12-3.78-12-9.44zm20.81 0c0-4.13-3.33-6.53-8.86-6.53-5.53 0-9 2.61-9 6.53 0 4.12 3.4 6.52 9 6.52s8.86-2.4 8.86-6.52zm.13-32.32h2.61v14.87h-.68c-5.57-5.67-11.4-11-15.59-11-3.26 0-4.71 2.16-4.71 5 .03 1.713.37 3.406 1 5l-2.57.62c-.74-1.8-1.14-3.723-1.17-5.67 0-4.39 2.34-7.89 7.07-7.89 5 0 11.2 5.83 14 8.75l.04-9.68zm-20.94-18.58c0-5.66 4.77-9.44 12-9.44s11.78 3.78 11.78 9.44-4.71 9.45-11.78 9.45c-7.07 0-12-3.78-12-9.45zm20.81 0c0-4.12-3.33-6.52-8.86-6.52-5.53 0-9 2.61-9 6.52 0 4.12 3.4 6.53 9 6.53s8.86-2.41 8.86-6.53z" />
        <path d="M1394 303.17V400H24.34V24.34H1394v75.83h24.34V0H0v424.34h1418.34V303.17H1394z" />
      </g>
    </svg>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

export { Logo };
